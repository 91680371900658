<template>
  <div id="Login">
    <v-container fluid style="z-index: 999; position: relative">
      <v-row align="center" class="pa-5">
        <v-col cols="12" class="d-flex justify-center">
          <v-card width="500px" class="pa-5 elevation-0">
            <h1 class="font-weight-bold">Welcome,</h1>
            <h2 class="font-weight-regular grey--text mb-16">Login using Organization</h2>
            <div>
              <v-text-field :rules="[rules.orgRequired]" @keyup.enter="login"
                            autofocus label="Organization*" outline ref="org" required
                            v-model="organization"></v-text-field>
            </div>
            <v-btn :loading="loading" @click="login" class="primary py-6 elevation-0 mt-5" width="100%">LOGIN
            </v-btn>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import NetworkCommunicator from '@/plugins/NetworkResourceHandler'
import Constants from '@/Constants/Constants'

export default {
  name: 'Login',
  data () {
    return {
      loading: false, toaster: {}, organization: null,
      rules: {
        orgRequired: value => !!value || 'Organization Required.',
      },
    }
  },
  beforeCreate () {
    if (localStorage.getItem('token') != undefined && localStorage.getItem('token') != '') {
      this.$router.replace({ path: '/login' })
    }
  },
  methods: {
    async login () {
      let self = this
      if (this.organization) {
        self.loading = true
        let response = await NetworkCommunicator('POST', `${Constants.server_url}login`,
            { organization: this.organization })
        if (response.flag) {
          self.toaster = { enable: true, color: 'green', message: 'Logged in successfully.' }
          localStorage.setItem('instances', JSON.stringify(response.data))
          localStorage.setItem('token', 'fake')
          setTimeout(function () {
            self.$router.push('/')
            self.$router.go(0)
          }, 50)
          self.loading = false
        } else {
          self.toaster = { enable: true, color: 'red', message: response.message }
          self.loading = false
        }
      }
    },
  },
}
</script>

<style scoped>

</style>
